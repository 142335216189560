import React, { useEffect } from "react";
import Avatar from "../assets/images/avatar.png";
import hr from "../assets/curve-hr.svg";
import AOS from "aos";
import "aos/dist/aos.css";

export default function AboutMe(props) {
  useEffect(() => {
    AOS.init({ duration: 1000 });
  }, []);

  return (
    <>
      <style>
        {`
    .hover-container:hover .absolute {
      opacity: 80%;
      background-color: rgba(0, 0, 0, 0.8);
    }
    .timeline {
      position: relative;
      margin: 2rem 0;
      padding: 0;
      list-style: none;
    }
    .timeline::before {
      content: '';
      position: absolute;
      left: 50%;
      width: 2px;
      background: #4a5568;
      height: 100%;
      transform: translateX(-50%);
    }
    .timeline-item {
      position: relative;
      margin: 2rem 0;
      text-align: left;
      width: 50%;
    }
    .timeline-item.left {
      float: left;
      clear: left;
    }
    .timeline-item.right {
      float: right;
      clear: right;
      text-align: right;
    }
    .timeline-item::before {
      content: '';
      position: absolute;
      top: 0;
      width: 20px;
      height: 20px;
      background: #4a5568;
      border-radius: 50%;
      z-index: 1;
      left: 50%;
      transform: translate(-50%, -50%);
    }
    .timeline-content {
      background: #2d3748;
      padding: 1rem 1.5rem;
      border-radius: 0.5rem;
      position: relative;
      z-index: 2;
    }
    .timeline-content span {
      display: block;
      font-size: 0.9rem;
      color: #a0aec0;
    }
    .timeline-content h3 {
      margin-top: 0.5rem;
      font-size: 1.25rem;
      color: #e2e8f0;
    }
    `}
      </style>
      <div id="about" className="mt-4 text-white">
        <h1 className="text-3xl font-bold mb-2">About Me</h1>
        <p className="font-light text-gray-400 text-lg">
          Passionate Full-Stack Developer with a love for solving complex
          problems and delivering impactful solutions.
        </p>
        <div
          className="flex flex-col md:flex-row items-center mt-8 p-6 border-2 border-gray-800 bg-dark-200 rounded-md"
          data-aos="fade-left"
        >
          {/* Avatar Section */}
          <div className="w-40 h-40 md:w-64 md:h-64 rounded-full overflow-hidden my-2 hover-container relative">
            <img
              src={Avatar}
              alt="Lukas Metzler"
              className="object-cover w-full h-full"
            />
            <div
              className="absolute top-0 left-0 w-full h-full flex flex-col items-center justify-center opacity-0 hover-content"
              style={{
                transition: "opacity 300ms ease-in-out",
              }}
            >
              <h3 className="text-white text-xl font-bold">Lukas Metzler</h3>
              <p className="text-gray-300 text-sm mt-2">Full-Stack Developer</p>
            </div>
            <div className="border-4 border-transparent border-t-4 border-gradient h-full w-full rounded-full animate-spin"></div>
          </div>
          {/* Info Section */}
          <div className="ml-4 mt-4 md:mt-0 md:ml-8">
            <ul className="list-disc list-inside text-gray-400 justify-items-start">
              <li className="text-lg md:text-xl font-bold text-gray-300">
                <strong>Age:</strong> <span className="font-light">24</span>
              </li>
              <li className="text-lg md:text-xl font-bold text-gray-300">
                <strong>Location:</strong>{" "}
                <span className="font-light">Austria / Tyrol</span>
              </li>
              <li className="text-lg md:text-xl font-bold text-gray-300">
                <strong>Profession:</strong>{" "}
                <span className="font-light">Full-Stack Developer</span>
              </li>
              <li className="text-lg md:text-xl font-bold text-gray-300">
                <strong>Education:</strong>{" "}
                <span className="font-light">
                  Electrical Engineer with Diploma, Currently Studying Computer
                  Science
                </span>
              </li>
              <li className="text-lg md:text-xl font-bold text-gray-300">
                <strong>Languages:</strong>{" "}
                <span className="font-light">
                  German (native), English (fluent in speaking and writing)
                </span>
              </li>
              <li className="text-lg md:text-xl font-bold text-gray-300">
                <strong>Hobbies:</strong>{" "}
                <span className="font-light">
                  Strength Training, Hiking, Coding Side Projects, Exploring
                  Technology Trends
                </span>
              </li>
            </ul>
          </div>
        </div>

        <div id="timeline" className="mt-8">
          <h2 className="text-2xl font-bold mb-6 text-center">My Journey</h2>
          <div className="relative max-w-4xl mx-auto">
            {/* Vertical Line */}
            <div className="absolute w-1 bg-gray-600 h-full left-1/2 transform -translate-x-1/2"></div>
            <div className="flex flex-col space-y-8">
              {/* Item 1 */}
              <div
                className="flex justify-between items-center w-full fade-in-left"
                data-aos="fade-right"
              >
                <div className="w-1/2 text-right pr-8">
                  <h3 className="text-xl font-semibold text-white">
                    &lt; 2019
                  </h3>
                  <p className="text-gray-400">
                    Multiple internships in software development and electrical
                    engineering.
                  </p>
                  <p className="text-gray-500 text-sm">
                    Graduated from HTL in Electrical Engineering with a focus on
                    Computer Science.
                  </p>
                </div>
                <div className="absolute bg-blue-500 w-8 h-8 rounded-full left-1/2 transform -translate-x-1/2 -translate-y-4 border-4 border-gray-800"></div>
                <div className="w-1/2"></div>
              </div>
              {/* Item 2 */}
              <div
                className="flex justify-between items-center w-full flex-row-reverse fade-in-right"
                data-aos="fade-left"
              >
                <div className="w-1/2 text-left pl-8">
                  <h3 className="text-xl font-semibold text-white">
                    2019 - 2023
                  </h3>
                  <p className="text-gray-400">
                    Started my studies while working at WheelE and pursuing
                    self-employment.
                  </p>
                  <p className="text-gray-500 text-sm">
                    Independently handled several large projects, including for
                    ÖSV, Stercom, Pharmaplan, and others.
                  </p>
                </div>
                <div className="absolute bg-green-500 w-8 h-8 rounded-full left-1/2 transform -translate-x-1/2 -translate-y-4 border-4 border-gray-800"></div>
                <div className="w-1/2"></div>
              </div>
              {/* Item 3 */}
              <div
                className="flex justify-between items-center w-full fade-in-left"
                data-aos="fade-right"
              >
                <div className="w-1/2 text-right pr-8">
                  <h3 className="text-xl font-semibold text-white">
                    2023 - Present
                  </h3>
                  <p className="text-gray-400">
                    Software Developer at Compax Digital in the
                    telecommunications sector.
                  </p>
                  <p className="text-gray-500 text-sm">
                    Focused on Java, Nuxt, React, Oracle, Postgres, Jenkins, and
                    more. Collaborative projects with HELLA, including a
                    real-time monitoring environment for smart home automation
                    as part of my Bachelor’s thesis, with more projects planned.
                  </p>
                </div>
                <div className="absolute bg-yellow-500 w-8 h-8 rounded-full left-1/2 transform -translate-x-1/2 -translate-y-4 border-4 border-gray-800"></div>
                <div className="w-5/12"></div>
              </div>
            </div>
          </div>
        </div>

        {/* Add Visual HR Line */}
        <img src={hr} className="w-full mt-8 md:h-3" alt="hr" />
      </div>
    </>
  );
}
