import "./App.css";
import { useEffect } from "react";
import Navbar from "./components/Navbar";
import Hiro from "./components/Hiro";
import Skills from "./components/Skills";
import Footer from "./components/Footer";
import About from "./components/About";
import UnderConstruction from "./components/UnderConstruction";
import AOS from "aos";
import "aos/dist/aos.css";
import { BrowserRouter as Router, Route, Link, Routes } from "react-router-dom";

function App() {
  useEffect(() => {
    document.title = "Lukas Metzler";
    AOS.init();
  }, []);

  return (
    <div className="bg-dark-500 min-h-screen">
      <Navbar />
      <div className="px-6 lg:px-20 xl:px-36 py-10">
        <Router>
          <Routes>
            <Route
              path="/"
              element={
                <>
                  <Hiro />
                  <About />
                  <Skills />
                  <Footer />
                </>
              }
            />
            <Route path="/blog" element={<UnderConstruction />} />
          </Routes>
        </Router>
      </div>
    </div>
  );
}

export default App;
