import { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faGithub,
  faInstagram,
  faLinkedinIn,
} from "@fortawesome/free-brands-svg-icons";
import hr from "../assets/curve-hr.svg";
import { useSpring, animated } from "react-spring";

export default function Hiro() {
  const [loaded, setLoaded] = useState(false);

  const strokeAnim = useSpring({
    from: { strokeDashoffset: 1000 },
    to: async (next) => {
      while (1) {
        await next({ strokeDashoffset: 0 });
        await next({ strokeDashoffset: 1000 });
      }
    },
    config: { duration: 4000 },
  });

  useEffect(() => {
    setLoaded(true);
  }, []);

  return (
    <>
      <style>
        {`
          .cls-1 {
            fill: rgba(255, 255, 255, 0.3);
            stroke: url(#gradient-default);
            stroke-width: 12;
            stroke-dasharray: 1000;
          }
          .svg-logo:hover .cls-1 {
            stroke: url(#gradient-hover);
          }
          @media screen and (max-width: 768px) {
            .svg-logo {
              width: 100%;
              height: 100%;
            }
          }
        `}
      </style>
      {loaded ? null : (
        <div className="fixed top-0 left-0 right-0 bottom-0 w-full h-screen z-50 overflow-hidden bg-white flex flex-col items-center justify-center">
          Loading...
        </div>
      )}
      <div
        id="home"
        className="flex w-full h-screen flex-col md:flex-row gap-5 items-center justify-center text-white relative"
      >
        <div className="md:w-3/6 md:p-4 flex items-center justify-center">
          <svg
            width="50%"
            height="50%"
            className="svg-logo"
            id="LM_logo"
            data-name="LM logo"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 2000 1313.98"
            preserveAspectRatio="xMidYMid meet"
          >
            <defs>
              <linearGradient
                id="gradient-default"
                x1="0%"
                y1="0%"
                x2="100%"
                y2="0%"
              >
                <stop
                  offset="0%"
                  style={{ stopColor: "#ffffff", stopOpacity: 1 }}
                />
                <stop
                  offset="100%"
                  style={{ stopColor: "#ff8c00", stopOpacity: 1 }}
                />
              </linearGradient>
              <linearGradient
                id="gradient-hover"
                x1="0%"
                y1="0%"
                x2="100%"
                y2="0%"
              >
                <stop
                  offset="0%"
                  style={{ stopColor: "#ffffff", stopOpacity: 1 }}
                />
                <stop
                  offset="100%"
                  style={{ stopColor: "#0079d3", stopOpacity: 1 }}
                />
              </linearGradient>
            </defs>
            <g id="LM">
              <g id="M_letter" data-name="M letter">
                <animated.polygon
                  className="cls-1"
                  points="2000 1313.98 1241.36 0.05 724.23 895.9 1000.09 895.9 1011.53 895.83 1241.41 497.7 1339.1 666.93 965.54 1313.98 1252.92 1313.93 1482.77 915.77 1580.46 1085 1448.31 1313.98 2000 1313.98"
                  style={strokeAnim}
                />
              </g>
              <g id="L_letter" data-name="L letter">
                <animated.polygon
                  className="cls-1"
                  points="900.27 252.33 902.3 248.8 758.64 0 0 1313.97 770.15 1313.97 913.77 1065.17 431.05 1065.17 900.27 252.33"
                  style={strokeAnim}
                />
              </g>
            </g>
          </svg>
        </div>
        <div
          className="md:w-3/6"
          data-aos="fade-right"
          data-aos-duration="1000"
          data-aos-offset="100"
        >
          <div className="flex flex-col w-full mt-8">
            <h1 className="text-xl text-gray-400">Hi, I'm</h1>
            <h1 className="text-2xl font-bold">Lukas Metzler</h1>
            <p className="text-xl font-bold text-gray-300">Software Engineer</p>
            <p className="text-md font-light text-gray-400 ">
              Enthusiastic and motivated self-learner in Information Technology,
              with a strong interest in exploring Full-Stack Development
              combined with DevOps and tackling complex business logic across
              various industrial and business sectors. Currently working as a
              Full-Stack Developer with a focus on the telecommunication sector,
              I bring several years of experience across diverse industries. I
              am now seeking opportunities to further deepen my expertise and
              contribute to innovative projects in my field.
            </p>
          </div>

          <ul className="flex mt-2 gap-4 items-center">
            <li>
              <a
                href="https://github.com/lukasmetzler"
                rel="noreferrer"
                target="_blank"
              >
                <FontAwesomeIcon size="2xl" icon={faGithub} />
              </a>
            </li>
            <li>
              <a
                href="https://www.linkedin.com/in/lukas-metzler-02bb001b9/?originalSubdomain=at"
                rel="noreferrer"
                target="_blank"
              >
                <FontAwesomeIcon size="2xl" icon={faLinkedinIn} />
              </a>
            </li>
          </ul>
        </div>
        <img src={hr} className="w-full md:h-2 absolute bottom-0" alt="hr" />
      </div>
    </>
  );
}
