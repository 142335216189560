import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import SkillCard from "./SkillCard.js";

import javascript from "../assets/icons/javascript/javascript-plain.svg";
import python from "../assets/skills/python.svg";
import reactIcon from "../assets/skills/react.svg";
import tailwind from "../assets/icons/tailwindcss/tailwindcss-plain.svg";
import mysql from "../assets/icons/mysql/mysql-original.svg";
import apache from "../assets/icons/apache/apache-original.svg";
import nginx from "../assets/icons/nginx/nginx-original.svg";
import git from "../assets/icons/git/git-plain.svg";
import express from "../assets/skills/express.svg";
import gitlab from "../assets/skills/gitlab.svg";
import PHP from "../assets/icons/php/php-plain.svg";
import Jenkis from "../assets/icons/jenkins/jenkins-original.svg";
import Nuxt from "../assets/icons/nuxtjs/nuxtjs-original.svg";
import Docker from "../assets/icons/docker/docker-original.svg";
import Java from "../assets/icons/java/java-original.svg";
import Spring from "../assets/icons/spring/spring-original.svg";

import hr from "../assets/curve-hr.svg";

export default function Skills() {
  const settings = {
    dots: false,
    autoplay: true,
    infinite: true,
    slidesToShow: 2,
    slidesToScroll: 1,
  };

  return (
    <div id="skills" className="mt-4 text-white">
      <h1 className="text-2xl font-bold">Skills</h1>
      <p className="font-light text-gray-400">Here are some of my skills</p>

      <div className="mt-4">
        <Slider {...settings}>
          <SkillCard name="Java" experience="5 years" img={Java} />
          <SkillCard name="Spring Boot" experience="5 years" img={Spring} />
          <SkillCard name="React" experience="5 years" img={reactIcon} />
          <SkillCard name="Nuxt" experience="2 years" img={Nuxt} />
          <SkillCard name="tailwind" experience="1 year" img={tailwind} />
          <SkillCard name="Jenkins" experience="3 years" img={Jenkis} />
          <SkillCard name="Nginx" experience="1 year" img={nginx} />
          <SkillCard name="Git" experience="4 years" img={git} />
          <SkillCard name="Docker" experience="3 year" img={Docker} />
          <SkillCard name="gitlab" experience="4 years" img={gitlab} />
          <SkillCard name="MySQL" experience="3 years" img={mysql} />
          <SkillCard name="python" experience="3 years" img={python} />
        </Slider>
      </div>
      <img src={hr} className="w-full mt-8 md:h-3" alt="hr" />
    </div>
  );
}
